import React from 'react'

import './infoTrack.scss'

const InfoTrack = () => {
  return (
    <div className="infotrack-wrapper">
      <div className="infotrack-container">
        <p>Uskoro u <span className="bih">BiH</span> i <span className="macedonia">Makedoniji</span></p>
      </div>
    </div>
  )
}

export default InfoTrack