import React from "react"
import logo from "../../images/icons/logo.svg"
import './loader.scss'

const Loader = () => {
  return (
    <div className="loader-wrapper">
      <img 
        src={logo}
        alt="MERE logo"
      />
    </div>
  )
}

export default Loader