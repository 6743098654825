import React, { lazy, Suspense } from "react"
import Loader from '../components/loader/Loader'

import { Helmet } from "react-helmet"


import InfoTrack from "../components/infoTrack/infoTrack"

const Layout = lazy(() => import('../components/layout/layout'))
const Hero = lazy(() => import('../components/sectionHero/sectionHero'))
const About = lazy(() => import('../components/sectionAbout/sectionAbout'))
const Points = lazy(() => import('../components/sectionPoints/sectionPoints'))
const Gallery = lazy(() => import('../components/sectionGallery/sectionGallery'))
const Requirements = lazy(() => import('../components/sectionRequirements/sectionRequirements'))
const Map = lazy(() => import('../components/sectionMap/sectionMap'))
const Contact = lazy(() => import('../components/sectionContact/sectionContact'))

const testPage = () => {
  const isSSR = typeof window === "undefined"
  return(
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MERE Marketi Srbija</title>
        <meta name="description" content="Mere marketi Srbija, ruski diskontni marketi konacno u Srbiji, diskont sa najpovoljnijim cenama" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" /> 
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
      </Helmet>
      {!isSSR && (
        <Suspense fallback={Loader()}>
          <Layout>
            <InfoTrack />
            <Suspense fallback={Loader()}>
              <Hero />
            </Suspense>
            <Suspense fallback={Loader()}>
              <About />
            </Suspense>
            <Suspense fallback={Loader()}>
              <Points />
            </Suspense>
            <Suspense fallback={Loader()}>
              <Gallery />
            </Suspense>
            <Suspense fallback={Loader()}>
              <Requirements />
            </Suspense>
            <Suspense fallback={Loader()}>
              <Map />
            </Suspense>
            <Suspense fallback={Loader()}>
              <Contact />
            </Suspense>
          </Layout>
        </Suspense>
      )}
    </>
  )
}

export default testPage

